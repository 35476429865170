<template>
  <b-row>
    <b-col cols="12">
     
      <table-kitchen-sink />
    
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import TableKitchenSink from './TableKitchenSink.vue'

export default {
  components: {
    BRow,
    BCol,

  
    TableKitchenSink,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
