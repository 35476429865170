import { render, staticRenderFns } from "./TableKitchenSink.vue?vue&type=template&id=200ca666&"
import script from "./TableKitchenSink.vue?vue&type=script&lang=js&"
export * from "./TableKitchenSink.vue?vue&type=script&lang=js&"
import style0 from "./TableKitchenSink.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TableKitchenSink.vue?vue&type=custom&index=0&blockType=b-card-body"
if (typeof block0 === 'function') block0(component)
import block1 from "./TableKitchenSink.vue?vue&type=custom&index=1&blockType=b-table&striped=true&hover=true&responsive=true&class=position-relative&%3Aper-page=perPage&%3Acurrent-page=currentPage&%3Aitems=items&%3Afields=fields&%3Asort-by.sync=sortBy&%3Asort-desc.sync=sortDesc&%3Asort-direction=sortDirection&%3Afilter=filter&%3Afilter-included-fields=filterOn&%40filtered=onFiltered"
if (typeof block1 === 'function') block1(component)
import block2 from "./TableKitchenSink.vue?vue&type=custom&index=2&blockType=b-card-body&class=d-flex%20justify-content-between%20flex-wrap%20pt-0"
if (typeof block2 === 'function') block2(component)

export default component.exports